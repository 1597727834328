<template>
    <v-container>
        <v-btn
            text
            color="primary-color"
            class="mb-n1"
            title="Back to Publish Amp"
            @click="back"
        >
            <v-icon small left>arrow-left</v-icon>
            Back to Publish Amp
        </v-btn>
        <loa-agency class="pa-0 mt-4" :company-id="companyId" @saved="back" />
    </v-container>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';

import { LoaAgency } from '@/components/LetterOfAuthorization/Agency';
import { Endpoint } from '@/mixins';
import { Announcement } from '@/types/Announcement';

@Component({
    components: {
        LoaAgency
    }
})
export default class Agency extends mixins(Endpoint) {
    companyId: number | null = null;

    announcement: Announcement | null = null;

    endpoint = '/announcements/publish';

    get announcementId() {
        return this.$route.params.announcementId;
    }

    get sourceUrl() {
        return [this.endpoint, this.announcementId].join('/');
    }

    onData(data: { announcement: Announcement }) {
        if (data.announcement) {
            this.announcement = data.announcement;
            this.companyId = this.announcement?.company_id || null;
        }
    }

    back() {
        this.$router.push(`/announcements/publish/${this.announcementId}`);
    }
}
</script>
