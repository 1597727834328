<template>
    <v-card>
        <v-row class="mx-4 my-0 mb-n4">
            <v-col>
                <v-card-title class="px-0">
                    Agency Letter of Authorization
                </v-card-title>
                <v-divider />
            </v-col>
        </v-row>
        <v-row class="ma-0 loa-container">
            <v-col cols="12" class="pa-0">
                <v-container>
                    <a-form ref="form" :submit="save" :disabled="isLoading">
                        <v-card-text>
                            <v-row>
                                <v-col>
                                    <h3>
                                        Upload your Agency Letter of
                                        Authorization
                                    </h3>
                                    <div class="px-0 pt-4 body-2">
                                        <p>
                                            For each whitelabel client, Yahoo
                                            requires a separate Letter of
                                            Authorization permitting your agency
                                            to publish amps on behalf of your
                                            client. You only need to complete
                                            this form once, but you will need
                                            your client's help to fill it, so we
                                            provide it as a PDF.
                                        </p>
                                        <p>
                                            If you are publishing this Amp on
                                            behalf of a company other than your
                                            own, please also upload an Agency
                                            LoA.
                                        </p>
                                        <p>
                                            The form can be found
                                            <a
                                                href="https://ampifire.com/files/documentation/Agency_PR_Authorization_Form.pdf"
                                                class="d-inline-block"
                                                target="_blank"
                                            >
                                                here
                                            </a>
                                            and instructions on completing it
                                            can be found
                                            <a
                                                href="https://docs.google.com/document/d/1MSVs1vRVFNQInqYDdB_6D0Bqz1V53lFnfnG1mcu5F5s/edit"
                                                class="d-inline-block"
                                                target="_blank"
                                            >
                                                here
                                            </a>
                                            .
                                        </p>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <a-media-uploader
                                        ref="uploader"
                                        accepted-file-types="application/pdf"
                                        :disabled="isLoading"
                                        :label="label"
                                        :options="options"
                                        :allow-process="false"
                                        @uploaded="updateView"
                                    />
                                </v-col>
                                <v-col v-if="hasLetter" cols="12" md="6">
                                    <a
                                        :href="letter"
                                        target="_blank"
                                        class="link-container font-weight-medium justify-center align-center d-flex pa-4"
                                    >
                                        <text-truncated :text="letterTitle" />
                                    </a>
                                </v-col>
                                <v-col v-else cols="12" md="6" class="ma-auto">
                                    <v-list-item-avatar
                                        height="100%"
                                        width="100%"
                                        class="rounded-lg pa-2 my-0"
                                        color="main-background"
                                    >
                                        <v-icon
                                            :size="60"
                                            color="grey lighten-2"
                                        >
                                            file-contract
                                        </v-icon>
                                    </v-list-item-avatar>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions class="mx-2 pb-4 pt-0">
                            <v-btn
                                type="submit"
                                color="primary"
                                :disabled="isLoading"
                                :loading="isSaving"
                                :block="$vuetify.breakpoint.smAndDown"
                            >
                                Upload
                            </v-btn>
                        </v-card-actions>
                    </a-form>
                </v-container>
            </v-col>
        </v-row>
    </v-card>
</template>

<script lang="ts">
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';

import { Endpoint } from '@/mixins';

import { TextTruncated } from '@/components/TextTruncated';

import { AForm } from '@/components/AForm';
import { AMediaUploader } from '@/components/AForm/Inputs/AMediaUploader';

import type { Announcement } from '@/types/Announcement';
import type { Company } from '@/types/Company';
import { MediaFile } from '@/types/MediaFile';

const LoaAgencyProps = Vue.extend({
    name: 'LoaAgency',
    props: {
        companyId: {
            type: [Number, String],
            default() {
                return 0;
            }
        }
    }
});

@Component<LoaAgency>({
    components: {
        AForm,
        AMediaUploader,
        TextTruncated
    },
    watch: {
        companyId() {
            this.onMounted();
        }
    }
})
export default class LoaAgency extends mixins(LoaAgencyProps, Endpoint) {
    $refs!: {
        uploader: InstanceType<typeof AMediaUploader>;
    };

    media: Partial<MediaFile> | null = null;
    announcement: Announcement | null = null;

    isSaving = false;

    endpoint = '/companies/edit';

    company: Partial<Company> = {};

    get sourceUrl() {
        return [this.endpoint, this.companyId].join('/');
    }

    get isBusy() {
        return this.isLoading || this.isSaving || this.isUpdating;
    }

    get label() {
        return `
                Drop file here / Click to select
                <br />
                <small>Only PDF files accepted. Maximum 5 MB.</small>
            `;
    }

    get hasLetter() {
        return Boolean(this.letter);
    }

    get letter() {
        return (
            this.media?.relative_filename ||
            this.company.agency_letter_of_authorization?.media_file
                .relative_filename ||
            void 0
        );
    }

    get letterTitle() {
        return (
            this.media?.title ||
            this.company.agency_letter_of_authorization?.media_file.title
        );
    }

    get options() {
        return {
            media_resource: {
                agency_letter_of_authorization_id: this.company.id
            },
            user_id: this.company.user_id,
            title: this.company.name
        };
    }

    onMounted() {
        if (this.companyId) {
            return this.load();
        }
    }

    onData(data: { company: Company }) {
        if (data.company) {
            this.company = data.company;
        }
    }

    save() {
        this.setSaving();

        this.$refs.uploader
            .tryToUpload()
            .then(this.onSaved.bind(this))
            .finally(() => {
                this.setSaving(false);
            });
    }

    setSaving(isSaving = true) {
        this.isSaving = isSaving;
    }

    updateView({ relative_filename, id, title }: Partial<MediaFile>) {
        if (id) {
            this.media = {
                id,
                title,
                relative_filename
            };
        }
    }

    onSaved() {
        this.$emit('saved');
    }
}
</script>

<style lang="scss">
$color: #fbfbfe;

.link-container {
    background: $color;
    border-radius: 0.5em;
    border: 1px dashed #d8d8d8;
    height: 100%;
    transition: background-color 0.3s ease;

    &:hover {
        background: darken($color, 2%);
    }
}
</style>
